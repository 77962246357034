import { HelpCircle, Pencil, PlusCircle, Search, Trash } from 'lucide-react'
import React, { Fragment, useEffect, useState } from 'react'
import chapterQueries from '../../../queries/chapterQueries'
import { ChapterFormData } from '../../../data/ChapterForm'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { setChapter, setMaster } from '../../../redux/reducers/masterReducers'
import { RouteConstant } from '../../../routes/RouteConstant'
import { useNavigate } from 'react-router-dom'
import { resetQuestionPage } from '../../../redux/reducers/questionReducers'
import { setLoader } from '../../../redux/reducers/optionsReducers'
import Loader from '../../../components/Loader'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography, createTheme } from '@mui/material'
import LoaderSmall from '../../../components/LoaderSmall'
import DrawerComponent from '../../../components/DrawerComponent'
import { Badge, Button } from '@nextui-org/react'
import { IconQuestionMark, IconTrash } from '@tabler/icons-react'
import { useApi } from '../../../components/ApiProvider'

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 13
  },
});

const ChapterScreen = () => {

  const navigate = useNavigate()

  const [result, setResult] = useState([])
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()
  const currentPage = useSelector((state)=> state.pagination.currentPage)
  const loader = useSelector((state)=> state.options.loader)

  const { apiData, setApiDataAndNotify } = useApi()

  const chapterList = chapterQueries.useChapterMutationList(
    (res)=>{
      dispatch(setLoader(false))
      setResult(res)
    }
  )

  useEffect(() => {
    dispatch(setLoader(true))
    chapterList.mutateAsync({search: search})
  }, [currentPage, search, apiData]);

  const createMaster = chapterQueries.useChapterMutationCreate(
    (res)=>{
      if(res?.message === "Error"){
        {res?.error?.map((error, index) => (
          Object?.values(error).map((errorMessage, innerIndex) => (
              toast.error(errorMessage)
            ))
        ))}
        return
    }
    if(res?.message=== "Success"){
        toast.success("Successfully created chapter")
        chapterList.mutateAsync({size: 10, page: currentPage})
    }}
  )

  const updateMaster = chapterQueries.useChapterMutationUpdate(
    (res)=>{
      if(res?.message === "Error"){
        {res?.error?.map((error, index) => (
          Object?.values(error).map((errorMessage, innerIndex) => (
              toast.error(errorMessage)
            ))
        ))}
        return
    }
    if(res?.message=== "Success"){
        toast.success("Successfully updated chapter")
        chapterList.mutateAsync({size: 10, page: currentPage})
    }
    }
  )

  const deleteMaster = chapterQueries.useChapterMutationDelete(
    (res)=>{
      toast.success("Successfully deleted chapter")
      setApiDataAndNotify(res)
    }
  )


  const navigateQuestion = (item)=>{
    dispatch(setChapter({
      id: item.id,
      chapter_no: item.chapter_no,
      name: item.name
    }))
    dispatch(resetQuestionPage())
    navigate(RouteConstant.QUESTIONLIST)
  }

  if(loader){
    return <Loader />
  }

  return (
    <div className='card-shadow m-10 max-[768px]:m-2 bg-white rounded-[10px]'>

        <div className='flex justify-between max-[995px]:flex-col items-center p-3 gap-y-3'>
            <div></div>
            {/* <div className='text-gray-600 text-[13px] font-medium'>{`Showing ${result?.data?.currentPage * 10 + 1} to ${result?.data?.currentPage * 10 + result?.data?.items?.length} of ${result?.data?.totalItems} entries`}</div> */}
            <div className='flex max-[561px]:flex-col max-[561px]:w-full justify-end items-center gap-3'>
                <input onChange={(e)=>setSearch(e.target.value)} type="text" className='w-[300px] max-[561px]:w-full h-[40px] border rounded-[7px] px-3 mt-[1px]' placeholder='Search' />
                <DrawerComponent entityForm={createMaster} inputFields={ChapterFormData} btnName={'Add Chapter'} header={'Add Chapter'} />
            </div>
        </div>

        {
            chapterList?.isLoading ?
            <div className="w-full flex items-center justify-center py-40">
                <LoaderSmall />
            </div>:
            <Fragment>
                <ThemeProvider theme={theme}>
                    <TableContainer
                    component={Paper}
                    sx={{
                        boxShadow: 'none',
                        width: '100%',
                        borderRadius: 0,
                        borderTop: '1px solid #e6e6e8',
                        borderBottom: '1px solid #e6e6e8',
                        fontWeight: 500, fontSize: "13px"
                    }}
                    >
                        <Table
                        aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center' width={30}>
                                        <b>#</b>
                                    </TableCell>
                                    <TableCell><b>Chapter Number</b></TableCell>
                                    <TableCell><b>Chapter Name</b></TableCell>
                                    <TableCell><b>Description</b></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {result?.data?.map((row, index) => (
                                    <TableRow
                                    key={row.id}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        fontFamily: 'Montserrat, sans-serif'
                                    }}
                                    >
                                        <TableCell align='center' width={30}>
                                            <Typography sx={{fontWeight: 500, fontSize: "13px"}}>
                                                {index + 1}
                                            </Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Typography sx={{fontWeight: 500, fontSize: "13px"}}>
                                                Chapter - {row?.chapter_no} 
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{fontWeight: 500, fontSize: "13px"}}>
                                                {row?.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{fontWeight: 500, fontSize: "13px"}}>
                                                {row?.description?.length > 100 ? row?.description?.slice(0, 100) + '...' : row?.description}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='center' sx={{display: 'flex', gap: 1}}>
                                          <Button isIconOnly onClick={()=>deleteMaster.mutateAsync(row.id)} color="danger" size="sm">
                                              <IconTrash size={16} />
                                          </Button>
                                          <DrawerComponent data={row} edit={true} entityForm={updateMaster} inputFields={ChapterFormData} btnName={'Update Chapter'} header={'Update Chapter'} />
                                          <Badge classNames={{
                                            badge: 'text-white bg-success text-[12px] font-bold',
                                          }} content={row?.questions?.length} color="success">
                                            <Button isIconOnly onClick={()=>navigateQuestion(row)} color="warning" size="sm">
                                                <IconQuestionMark size={16} />
                                            </Button>
                                          </Badge>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ThemeProvider>
            </Fragment>
        }


    </div>
  )
}

export default ChapterScreen