import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderText, setLoader } from '../../redux/reducers/optionsReducers';
import BreadCrumbs from '../../components/BreadCrumbs';
import { RouteConstant } from '../../routes/RouteConstant';
import questionQueries from '../../queries/questionQueries';
import { CheckCircle2, ChevronRight, PauseCircle, X, XCircle } from 'lucide-react';
import { IconCircleCheck, IconCircleX, IconX } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { setQuestion } from '../../redux/reducers/masterReducers';
import ProgressBar from '../../components/ProgressBar';
import Loader from '../../components/Loader';
import { decode } from 'html-entities';

const breadcrumb = [
  {
    label: "Vessel",
    path: RouteConstant.VESSEL,
    roles: ["SADMIN"]
  },
  {
    label: "Vessel Details",
    roles: ["CENG", "CAPT", "2E", "3E", "CMATE", "2M", "3M"],
    path: RouteConstant.VESSELDETAILS
  },
  {
    label: "Inspection History",
    path: RouteConstant.INSPECTIONHISTORY
  },
  {
    label: "Chapter Progress",
    path: RouteConstant.CHAPTERPROGRESS
  },
  {
    label: "Question Progress",
  },
]

const QuestionProgress = () => {

  const chapter = useSelector((state)=> state.master.chapter)
  const inspection = useSelector((state)=> state.master.inspection)
  const loader = useSelector((state)=> state.options.loader)
  const master = useSelector((state)=> state.master)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [percentage, setPercentage] = useState(0)
  const [result, setResult] = useState([])

  useEffect(() => {
      dispatch(setLoader(true))
      dispatch(setHeaderText(`Chapter : ${chapter?.name}`))
  }, []);

  const questionList = questionQueries.useQuestionListingMutationInspection(
    (res)=>{
      dispatch(setLoader(false))
      setResult(res)
      percentageOfCheck(res?.data)
    }
  )

  useEffect(() => {
    questionList.mutateAsync({chapter:chapter.id, inspection: inspection, user: master?.isUserWise ? master?.user?.id : ""})
  }, []);

  const questionCard = (item)=>{
    const data = item?.checklists
    const success = data?.filter(item=> item?.checklistaction?.action === "YES")
    const failed = data?.filter(item=> item?.checklistaction?.action === "NO")
    if(success?.length === data?.length){
      return "green"
    }else if(failed.length > 0){
      return "red"
    }else{
      return "yellow"
    }
  }

  const handleQuestionDetail=(item, index)=>{
    dispatch(setQuestion({
      id: item?.id,
      question: item?.question
    }))
    navigate(RouteConstant.QUESTIONDETAIL)
  }

  const percentageOfCheck = (data)=>{
    if(data?.length === 0){
      return
    }
    let totalCheckList = 0
    let success = 0
    data?.map((item)=>{
      item?.checklists?.map(item=>{
        if(!item.not_applicable_checklist){
          totalCheckList += 1
        }
      })
      success += item?.checklists?.filter(item=> (item?.checklistaction?.action === "YES" || item?.checklistaction?.action === "NO") && !item.not_applicable_checklist)?.length
    })
    const percentage = (success / totalCheckList) * 100
    if(isNaN(percentage)){
      setPercentage(0)
    }else{
        setPercentage(percentage)
    }
  }

  if(loader){
    return <Loader />
  }


  return (
    <Fragment>

      <div className='flex justify-between mb-4 items-center max-sm:flex-col-reverse gap-6'>
          <BreadCrumbs data={breadcrumb} />
          <ProgressBar data={percentage} />
      </div>

      <div className="question-list-cntnr mt-4">
        <section>
          <div className="question-list-wrpr">
            {
              result?.data?.map((item, index)=>(
                <div className={`question-and-checklist ${questionCard(item)}-card bg-white`} key={index}>
                  <div className="question-card">
                    <div className="question-number">
                      <div className="question-label">
                        Question<span>{chapter.chapter_no}.{item?.section}.{item?.sub_section}</span>
                      </div>
                    </div>
                    <div className="question-process-human-hardware-wrpr w-full flex justify-between !items-center">
                      <div className="question-cntnt gap-1">
                        {decode(item?.question)}
                        <div className="text-white text-[10px] font-bold bg-green-400 px-2 rounded w-fit capitalize text-center py-[2px]">{(item?.tag)}</div>
                      </div>
                    </div>
                    <button onClick={()=>{handleQuestionDetail(item, index)}} className="question-link-wrpr">
                      <div>
                        <ChevronRight size="18" />
                      </div>
                    </button>
                  </div>
                  <div className="checklist-card flex items-center gap-3">
                    <div className="checklist-label">Checklist</div>
                    <div className='flex gap-3'>
                      {
                        item?.checklists?.map((check, cindex)=>{
                          if(check?.checklistaction?.action === "YES"){
                            return(
                              <div className='flex justify-center items-center flex-col gap-1'>
                                <div className={`bg-gradient-to-r from-[#10b981] to-[#059669] flex justify-center items-center h-[38px] w-[38px] rounded-md ${check?.not_applicable_checklist ? "opacity-80": "opacity-100"}`}>
                                  <CheckCircle2 color="white" size="18" />
                                </div>
                                <span className='text-[9px] font-semibold uppercase bg-gradient-to-r from-[#10b981] to-[#059669] bg-clip-text text-transparent'>Positive</span>
                              </div>
                            )
                          }else if(check?.checklistaction?.action === "NO"){
                            return(
                              <div className='flex justify-center items-center flex-col gap-1'>    
                                <div className={`bg-gradient-to-r from-[#ef4444] to-[#dc2626] flex justify-center items-center h-[38px] w-[38px] rounded-md ${check?.not_applicable_checklist ? "opacity-80": "opacity-100"}`}>
                                  <XCircle color="white" size="18" />
                                </div>
                                <span className='text-[9px] font-semibold uppercase bg-gradient-to-r from-[#ef4444] to-[#dc2626] bg-clip-text text-transparent'>Negative</span>
                              </div>
                            )
                          }else{
                            return(
                              <div className='flex justify-center items-center flex-col gap-1'>    
                                <div className={`bg-gradient-to-r from-[#f59e0b] to-[#d97706] flex justify-center items-center h-[38px] w-[38px] rounded-md ${check?.not_applicable_checklist ? "opacity-80": "opacity-100"}`}>
                                  <PauseCircle color="white" size="18" />
                                </div>
                                <span className='text-[9px] font-semibold uppercase bg-gradient-to-r from-[#f59e0b] to-[#d97706] bg-clip-text text-transparent'>Not Seen</span>
                              </div>
                            )
                          }
                        })
                      }
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </section>
      </div>

    </Fragment>
  )
}

export default QuestionProgress