import React, { Fragment, useEffect, useState } from 'react'
import questionQueries from '../../../queries/questionQueries'
import { ChevronLeft, Eye, Pencil, PlusIcon, Trash } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { RouteConstant } from '../../../routes/RouteConstant'
import { useDispatch, useSelector } from 'react-redux'
import { removeQuestionId, setQuestionId } from '../../../redux/reducers/questionReducers'
import { removeChapter } from '../../../redux/reducers/masterReducers'
import { setLoader } from '../../../redux/reducers/optionsReducers'
import Loader from '../../../components/Loader'
import { decode } from 'html-entities'

const QuestionList = () => {

    const navigate = useNavigate()
    const [result, setResult] = useState([])
    const chapter = useSelector((state)=> state.master.chapter)
    const dispatch = useDispatch()
    const currentPage = useSelector((state)=> state.question.currentPage)
    const loader = useSelector((state)=> state.options.loader)

    const questionList = questionQueries.useQuestionListMutation(
        (res)=>{
            dispatch(setLoader(false))
            setResult(res)
        }
    )

    useEffect(() => {
        dispatch(setLoader(true))
        questionList.mutateAsync({chapter_id: chapter?.id})
    }, [currentPage]);

    const handleNavigate=(id)=>{
        dispatch(setQuestionId(id));
        navigate(RouteConstant.QUESTIONFORM)
    }

    const handleNavigateCreate=()=>{
        dispatch(removeQuestionId());
        navigate(RouteConstant.QUESTIONFORM)
    }

    const navigateBack =()=>{
        navigate(RouteConstant.CHAPTER)
        dispatch(removeChapter())
    }

    if(loader){
        return <Loader />
    }

  return (
    <Fragment>
        <div>
            <div className='flex justify-between max-md:flex-col-reverse max-md:gap-2 items-center'>
                <div className='flex gap-6 items-center'>
                    <ChevronLeft size="16" className='cursor-pointer' onClick={navigateBack} />
                    <div className='font-medium text-[16px]'>
                        {chapter?.chapter_no} {chapter?.name}
                    </div>
                </div>
                <div>
                    <button onClick={handleNavigateCreate} className='bg-primary text-white px-4 py-2 rounded-lg flex items-center gap-2'>
                        <PlusIcon size={18}/> Add Question
                    </button>
                </div>
            </div>
            <div className='mt-8'>
                {result?.data?.map((item, index)=>(
                    <div key={index} className='w-full my-4 bg-questionCard p-4 rounded-lg border-[1px] border-questionBorder'>
                        <div className='grid gap-6 grid-cols-12 items-start'>
                            <div className='col-span-1 max-lg:col-span-12 text-white'>
                                <div className='bg-primary p-2 rounded-lg max-w-[100px] text-center text-[15px] font-bold'>{chapter?.chapter_no}.{item?.section}.{item?.sub_section}</div>
                            </div>
                            <div className='col-span-9 max-lg:col-span-12'>
                                <div className='font-bold'>Question</div>
                                <div className='font-light text-[12px]'>{decode(item?.question)}</div>
                                <div className='mt-4'>
                                    <div className='grid grid-cols-12 max-lg:grid-cols-6 max-md:grid-cols-3 gap-4'>
                                        <div className='col-span-3 bg-white rounded-lg shadow-md p-4'>
                                            <div className='font-semibold'>Short Question</div>
                                            <div className='font-light text-[12px] text-gray-600'>{item?.short_question}</div>
                                        </div>
                                        <div className='col-span-3 bg-white rounded-lg shadow-md p-4'>
                                            <div className='font-semibold'>ROVIQ Sequence</div>
                                            <div className='font-light text-[12px] text-gray-600'>{item?.roviq}</div>
                                        </div>
                                        <div className='col-span-3 bg-white rounded-lg shadow-md p-4'>
                                            <div className='font-semibold'>Publications</div>
                                            <div className='font-light text-[12px] text-gray-600'>{item?.publications}</div>
                                        </div>
                                        <div className='col-span-3 bg-white rounded-lg shadow-md p-4'>
                                            <div className='font-semibold'>Photography Mandatory</div>
                                            <div className='font-light text-[12px] text-gray-600'>{item?.is_photograph}</div>
                                        </div>
                                        <div className='col-span-3 bg-white rounded-lg shadow-md p-4'>
                                            <div className='font-semibold'>Process</div>
                                            <div className='font-light text-[12px] text-gray-600'>{item?.process}</div>
                                        </div>
                                        <div className='col-span-3 bg-white rounded-lg shadow-md p-4'>
                                            <div className='font-semibold'>Human</div>
                                            <div className='font-light text-[12px] text-gray-600'>{item?.human}</div>
                                        </div>
                                        <div className='col-span-3 bg-white rounded-lg shadow-md p-4'>
                                            <div className='font-semibold'>Hardware</div>
                                            <div className='font-light text-[12px] text-gray-600'>{item?.hardware}</div>
                                        </div> 
                                        <div className='col-span-3 bg-white rounded-lg shadow-md p-4'>
                                            <div className='font-semibold'>Tag</div>
                                            <div className='font-light text-[12px] text-gray-600'>{item?.tag}</div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-2 max-lg:col-span-12 flex gap-2 justify-end'>
                                <button className='w-[35px] h-[35px] flex justify-center items-center bg-primary rounded-lg'><Eye color='white' size="15" /></button>
                                <button onClick={()=>{handleNavigate(item.id)}} className='w-[35px] h-[35px] flex justify-center items-center bg-primary rounded-lg'><Pencil color='white' size="15" /></button>
                                <button className='w-[35px] h-[35px] flex justify-center items-center bg-red-500 rounded-lg'><Trash color='white' size="15" /></button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </Fragment>
  )
}

export default QuestionList
