import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Checkbox, Input, Link} from "@nextui-org/react";
import { IconMail } from "@tabler/icons-react";
import { Fragment, useState } from "react";
import inspectionQueries from "../../queries/inspectionQueries";
import { generateValidationSchema } from "../../utils/validationSchema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from "../../components/Loader";
import LoaderSmall from "../../components/LoaderSmall";

const ReportModal =({vessel, id})=> {

    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const inspectionExport = inspectionQueries.useInspectionFullReport(
        async(res)=>{
            if(res?.success){
                setIsSuccess(true)
                setIsLoading(false)
            }
        }
    )

    const inputFields = [
        {
            name: "email",
            label: "Email",
            type: "email",
            required: true,
        }
    ]

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    });

    const onSubmit = (data) => {
        const newData = {
            email: data.email,
            vessel: vessel,
            id: id
        }
        setIsLoading(true)
        inspectionExport.mutateAsync(newData)
    }

    const handleClose = () => {
        onOpenChange(false)
        setIsSuccess(false)
        setIsLoading(false)
    }

    return (
        <Fragment>
            <Button onPress={onOpen} className="text-[12px] flex items-center gap-2 shadow-md px-4 py-[7px] rounded-md text-white bg-primary">Full Report</Button>
            <Modal 
            isOpen={isOpen} 
            onOpenChange={onOpenChange}
            onClose={handleClose}
            placement="top-center"
            >
            <ModalContent>
                {(onClose) => (
                    isLoading ?
                    <div className="flex justify-center items-center min-h-[10vh]">
                        <LoaderSmall />
                    </div> :
                    isSuccess ?
                    <Fragment>
                    <div className="text-[14px] font-semibold text-center justify-center items-center gap-4 mt-3 p-6">
                        <div>Your report is being generated. Please check your inbox shortly.</div>
                    </div>
                    </Fragment>:
                    <Fragment>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <ModalHeader className="flex flex-col gap-1">Send report to email</ModalHeader>
                            <ModalBody>
                                <Input
                                {...register("email")}
                                type="email"
                                autoFocus
                                endContent={
                                    <IconMail size={18} className="text-default-400 pointer-events-none flex-shrink-0" />
                                }
                                placeholder="Enter your email"
                                classNames={{
                                    input: "outline-none",
                                }}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button type="button" color="danger" variant="flat" onPress={handleClose}>
                                Close
                                </Button>
                                <Button type="submit" color="primary">
                                Generate Report
                                </Button>
                            </ModalFooter>
                        </form>
                    </Fragment>
                )}
                </ModalContent>
            </Modal>
        </Fragment>
    );
}

export default ReportModal