import React, { useEffect, useState } from 'react';
import { Ship, Shield, FileCheck, ChevronRight, Anchor } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { generateValidationSchema } from '../../utils/validationSchema';
import authQueries from '../../queries/authQueries';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const DemoRegistration = () => {

    const navigate = useNavigate()

    const [countdown, setCountdown] = useState(10);

    const requiredFields = [
        { name: 'company_name', label: 'Company Name', type: 'text', required: true },
        { name: 'contact_person', label: 'Contact Person', type: 'text', required: true },
        { name: 'email', label: 'Work Email', type: 'email', required: true },
        { name: 'mobile', label: 'Phone Number', type: 'text', required: true }
    ];

    const { register, handleSubmit, formState: { errors }, setError } = useForm({
        resolver: yupResolver(generateValidationSchema(requiredFields))
    });

    const features = [
        { icon: Ship, text: "Complete SIRE 2.0 Prep vessel inspection toolkit" },
        { icon: Shield, text: "Advanced maritime safety compliance system" },
        { icon: FileCheck, text: "Comprehensive inspection reports and analytics" }
    ];



    const demoRegistration = authQueries.useDemoRegistrationMutation(
        (res) => {
            if(res?.success){
            }else{
                if(res?.field === 'email'){
                    setError('email', { type: 'manual', message: res?.message });
                }
                toast.error(res?.message || 'Something went wrong');
            }
        }
    )

    useEffect(() => {
        if(demoRegistration.isSuccess && demoRegistration.data?.success){
            setCountdown(10);
            const timer = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);

            setTimeout(() => {
                navigate('/');
            }, 10000);

            return () => clearInterval(timer);
        }
    }, [demoRegistration.isSuccess]);

    const onSubmit = (data) => {
        demoRegistration.mutateAsync(data);
    };

    return (
        <div className="relative min-h-screen bg-gradient-to-b from-slate-900 via-slate-800 to-slate-900">

            {demoRegistration.isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
                    <div className="flex flex-col items-center">
                        <div className="w-16 h-16 border-4 border-blue-400 border-t-transparent rounded-full animate-spin"></div>
                        <p className="mt-4 text-white text-lg font-medium">Submitting your request...</p>
                    </div>
                </div>
            )}

            {(demoRegistration.isSuccess && demoRegistration.data?.success) && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <h2 className="text-2xl font-bold text-green-600">Registration Successful!</h2>
                        <p className="text-gray-700 mt-2">Your trial access request has been submitted.</p>
                        <p className="text-gray-700 mt-2">Check your email for login details.</p>
                        <p className="text-gray-500 mt-4">Redirecting in <span>{countdown}</span> seconds...</p>
                    </div>
                </div>
            )}


            <div className="max-w-7xl mx-auto px-4 py-12">
                <div className="text-center mb-12">
                    <div className="flex items-center justify-center gap-3 mb-6">
                        <img src={require('../../assets/images/logo-white.png')} alt="SolMarine Tech" className="w-[160px]" />
                    </div>
                    <h2 className="text-4xl md:text-5xl font-bold text-white mb-4 font-montserrat">
                        SIRE 2.0 Prep
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-cyan-400 font-montserrat"> Trial Access</span>
                    </h2>
                    <p className="text-lg text-slate-300 max-w-4xl mx-auto">
                        Experience next-generation vessel inspection technology with our comprehensive SIRE 2.0 Prep platform
                    </p>
                    <p className="text-lg text-slate-300 max-w-4xl mx-auto mt-1">
                        When you register for a trial, we will email you with the links for downloading the SIRE 2.0 prep app and the login credentials to check it out.
                    </p>
                </div>

                <div className="grid md:grid-cols-2 gap-12 items-start">
                    <div className="space-y-8">
                        <div className="grid gap-6">
                            {features.map((Feature, index) => (
                                <div key={index} className="flex items-center gap-4 bg-slate-800/50 p-4 rounded-xl border border-slate-700">
                                    <div className="p-2 bg-blue-500/10 rounded-lg">
                                        <Feature.icon className="w-6 h-6 text-blue-400" />
                                    </div>
                                    <span className="text-slate-300">{Feature.text}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="relative">
                        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-cyan-500/10 rounded-2xl blur-xl" />
                        <div className="relative bg-slate-800/80 backdrop-blur-xl rounded-2xl p-8 border border-slate-700">
                            <h2 className="text-2xl font-semibold text-white mb-6">Register for Trial Access</h2>
                            
                            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                                <div className="space-y-4">
                                    {requiredFields.map((field, index) => (
                                        <div key={index}>
                                            <label className="block text-sm font-medium text-slate-300 mb-1">
                                                {field.label}
                                            </label>
                                            {field.type === 'select' ? (
                                                <select
                                                className="w-full px-4 h-[42px] bg-slate-900/50 border border-slate-600 rounded-lg text-white placeholder-slate-500 focus:outline-none focus:border-blue-500 transition-colors"
                                                {...register(field.name)}
                                                >
                                                    <option value="">Select {field.label.toLowerCase()}</option>
                                                    {field.options.map((option) => (
                                                        <option key={option} value={option}>{option}</option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <input
                                                type={field.type}
                                                className="w-full px-4 h-[42px] bg-slate-900/50 border border-slate-600 rounded-lg text-white placeholder-slate-500 focus:outline-none focus:border-blue-500 transition-colors"
                                                placeholder={`Enter ${field.label.toLowerCase()}`}
                                                {...register(field.name)}
                                                />
                                            )}
                                            {errors[field.name] && <p className="text-red-500 text-sm mt-1">{errors[field.name].message}</p>}
                                        </div>
                                    ))}
                                </div>

                                <button
                                    type="submit"
                                    className="w-full group relative px-6 py-3 bg-gradient-to-r from-blue-500 to-cyan-500 rounded-lg text-white font-medium transition-all duration-200 hover:shadow-lg hover:shadow-blue-500/30 active:scale-[0.98]"
                                >
                                    <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-cyan-600 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity" />
                                    <span className="relative flex items-center justify-center gap-2">
                                        Register for Trial Access
                                        <ChevronRight className="w-5 h-5" />
                                    </span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DemoRegistration;