import React, { Fragment } from 'react'
import '../assets/css/loader.css'

const Loader = (props) => {
  return (
    <div className="flex justify-center items-center">
      <img src="https://cdn.dribbble.com/users/197853/screenshots/5506993/media/c95efedb260edbb304465a81f3558024.gif" width="200" />
    </div>
  )
}

export default Loader
