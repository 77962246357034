export const VesselFormData = [
    {
        name: "name",
        label: "Vessel Name",
        type: "text",
        required: true,
        max:255,
    },
    {
        name: "imo_no",
        label: "IMO Number",
        type: "number",
        required: true,
    },
    {
        name: "built",
        label: "Built",
        type: "number",
        required: true,
        grid: 6
    },
    {
        name: "type",
        label: "Type",
        type: "text",
        required: true,
    },
    {
        name: "dwt",
        label: "DWT",
        type: "number",
        required: true,
    },
    {
        name: "chemical",
        label: "Chemical",
        type: "checkbox",
        required: false,
    },
    {
        name: "oil",
        label: "Oil",
        type: "checkbox",
        required: false,
    },
    {
        name: "lng",
        label: "LNG",
        type: "checkbox",
        required: false,
    },
    {
        name: "lpg",
        label: "LPG",
        type: "checkbox",
        required: false
    }
]

export const VesselEditFormData = [
    {
        name: "name",
        label: "Vessel Name",
        type: "text",
        required: true,
        max:255,
    },
    {
        name: "imo_no",
        label: "IMO Number",
        type: "number",
        required: true,
    },
    {
        name: "built",
        label: "Built",
        type: "number",
        required: true,
        grid: 6
    },
    {
        name: "type",
        label: "Type",
        type: "text",
        required: true,
    },
    {
        name: "dwt",
        label: "DWT",
        type: "number",
        required: true,
    } 
]
