
import React from "react";
import { Routes, Route, } from "react-router-dom";  
import { RouteConstant } from "./RouteConstant";
import PageLayout from "../screens/Layout/PageLayout";
import LoginScreen from "../screens/Login/LoginScreen";
import QuestionForm from "../screens/Master/Questions/QuestionForm";
import QuestionList from "../screens/Master/Questions/QuestionList";
import ChapterScreen from "../screens/Master/Chapter/ChapterScreen";
import VesselScreen from "../screens/Vessel/VesselScreen";
import VesselDetailsScreen from "../screens/Vessel/VesselDetailsScreen";
import InspectionHistory from "../screens/InspectionHistory/InspectionHistory";
import ChapterProgress from "../screens/ChapterProgress/ChapterProgress";
import QuestionProgress from "../screens/QuestionProgress.js/QuestionProgress";
import QuestionDetailScreen from "../screens/QuestionProgress.js/QuestionDetailScreen";
import OcimfScreen from "../screens/Vessel/ocimfvessel";
import PdfGenerator from "../screens/InspectionHistory/PdfGenerator";
import CompanyProfileScreen from "../screens/User/CompanyProfileScreen";
import UsersList from "../screens/User/UsersList";
import DocumentScreen from "../screens/Documents/DocumentScreen";
import NotFound from "../components/NotFound";

const SuperAdminRoutes = () => {
  return (
    <Routes>
        <Route exact path={RouteConstant.ROOT} element={ <LoginScreen /> } />
        <Route path={RouteConstant.ROOT} element={<PageLayout />}>
            <Route path={RouteConstant.DASHBOARD} element={ <VesselScreen /> } />
            <Route path={RouteConstant.QUESTIONFORM} element={ <QuestionForm /> } />
            <Route path={RouteConstant.QUESTIONLIST} element={ <QuestionList /> } />
            <Route path={RouteConstant.CHAPTER} element={ <ChapterScreen /> } />
            <Route path={RouteConstant.PROFILE} element={ <CompanyProfileScreen /> } />
            <Route path={RouteConstant.USERS} element={ <UsersList /> } />
            <Route path={RouteConstant.VESSEL} element={ <VesselScreen /> } />
            <Route path={RouteConstant.VESSELDETAILS} element={ <VesselDetailsScreen /> } />
            <Route path={RouteConstant.INSPECTIONHISTORY} element={ <InspectionHistory /> } />
            <Route path={RouteConstant.CHAPTERPROGRESS} element={ <ChapterProgress /> } />
            <Route path={RouteConstant.QUESTIONPROGRESS} element={ <QuestionProgress /> } />
            <Route path={RouteConstant.QUESTIONDETAIL} element={ <QuestionDetailScreen /> } />
            <Route path={RouteConstant.OCIMFVESSEL} element={ <OcimfScreen /> } />
            <Route path={RouteConstant.INSPECTIONREPORT} element={ <PdfGenerator /> } />
            <Route path={RouteConstant.DOCUMENTS} element={ <DocumentScreen /> } />
        </Route>
        <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default SuperAdminRoutes;