import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { Transfer } from "antd";
import { useSelector } from "react-redux";
import { IconPlus, IconChevronRight, IconFilter } from "@tabler/icons-react";
import questionQueries from "../../queries/questionQueries";
import { useApi } from "../../components/ApiProvider";

const AssignModal = ({ data, assigned }) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const master = useSelector((state) => state.master);
  const { setApiDataAndNotify } = useApi();

  const questionsList = questionQueries.useQuestionChapterWiseList(
    (res) => {
      getMock(res?.data);
    }
  );

  const handleOpen = () => {
    questionsList.mutateAsync({ vessel: master.id, chapter: data.id });
    onOpen();
  };

  const [targetKeys, setTargetKeys] = useState([]);
  const [mockData, setMockData] = useState([]);
  const [searchText, setSearchText] = useState("");

  const getMock = (qst) => {
    let tempMockData = [];
    let tempTargetKey = [];
    qst?.map((element, index) => {
      const newData = {
        key: element.id,
        title: (
          <div className="font-poppins text-sm">
            <span className="font-medium">
              {`${data?.chapter_no}.${element?.section !== null ? element?.section : 0}.${
                element?.sub_section !== null ? element?.sub_section : 0
              }`}
            </span>
            <span className="mx-2">{element.short_question}</span>
            <span className="text-xs px-2 py-1 bg-blue-100 text-blue-700 rounded-full ml-1">
              {element?.tag}
            </span>
          </div>
        ),
        description: element.short_question,
        tag: element?.tag
      };
      tempMockData.push(newData);
      if (element?.vessel_questions?.length > 0) {
        tempTargetKey.push(element.id);
      }
    });

    if (assigned?.length > 0) {
      assigned.map((element) => {
        tempTargetKey.push(element.id);
      });
    }
    setTargetKeys(tempTargetKey);
    setMockData(tempMockData);
  };

  const assignQuestion = questionQueries.useQuestionAssign((res) => {
    setApiDataAndNotify(res);
    questionsList.mutateAsync({ vessel: master.id, chapter: data.id });
  });

  const handleChange = (newTargetKeys) => {
    const dataValues = {
      vessel_id: master.id,
      questions: newTargetKeys,
      chapter_id: data.id
    };
    assignQuestion.mutateAsync(dataValues);
    setTargetKeys(newTargetKeys);
  };

  const filterOption = (inputValue, option) => {
    return option.description.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 || 
           option.tag.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
  };

  return (
    <Fragment>
      <button
        onClick={handleOpen}
        className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-50 text-blue-600 hover:bg-blue-100 transition-colors"
        aria-label="Assign questions"
      >
        <IconPlus size="16" />
      </button>
      <Modal
        backdrop="blur"
        size="full"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        classNames={{
          backdrop: "bg-gradient-to-t from-zinc-900/80 to-zinc-900/40 backdrop-blur-sm",
          base: "border border-zinc-200 dark:border-zinc-800 shadow-xl",
          header: "border-b border-zinc-200 dark:border-zinc-800",
          footer: "border-t border-zinc-200 dark:border-zinc-800",
          closeButton: "hover:bg-zinc-200 active:bg-zinc-300 dark:hover:bg-zinc-800"
        }}
      >
        <ModalContent>
          {(onClose) => (
            <Fragment>
              <ModalHeader className="flex flex-col gap-1">
                <h3 className="text-xl font-bold text-center">Assign Questions</h3>
                <p className="text-zinc-500 text-sm font-normal text-center">
                  Chapter {data?.chapter_no} - {data?.name || ""}
                </p>
              </ModalHeader>
              <ModalBody>
                <div className="p-4">
                  <Transfer
                    dataSource={mockData}
                    showSearch
                    filterOption={filterOption}
                    listStyle={{
                      width: "50%",
                      height: "600px",
                      overflow: "auto",
                      borderRadius: "0.5rem",
                      boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
                    }}
                    targetKeys={targetKeys}
                    onChange={handleChange}
                    render={(item) => item.title}
                    titles={["Available Questions", "Assigned Questions"]}
                    operations={["Assign →", "← Remove"]}
                    className="custom-transfer"
                  />
                </div>
              </ModalBody>
            </Fragment>
          )}
        </ModalContent>
      </Modal>

      {/* Add this CSS to your global styles */}
      <style jsx global>{`
        .custom-transfer .ant-transfer-list-header {
          background: #f8fafc;
          border-radius: 0.5rem 0.5rem 0 0;
          padding: 12px 16px;
        }
        .custom-transfer .ant-transfer-list-content-item {
          transition: all 0.2s;
        }
        .custom-transfer .ant-transfer-list-content-item:hover {
          background-color: #f0f9ff;
        }
        .custom-transfer .ant-transfer-list-content {
          padding: 8px 0;
        }
        .custom-transfer .ant-transfer-operation {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }
        .custom-transfer .ant-transfer-operation button {
          border-radius: 0.375rem;
          height: 40px;
          font-weight: 500;
        }
      `}</style>
    </Fragment>
  );
};

export default AssignModal;