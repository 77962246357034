import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react'
import { LogOut, Bell, Settings, Search, User, Calendar, HelpCircle } from 'lucide-react'
import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/reducers/authReducers' 
import { RouteConstant } from '../../routes/RouteConstant'
import { useNavigate } from 'react-router-dom'
import { setHeaderTextIntitial } from '../../redux/reducers/optionsReducers'
import AvatarBorder from '../../components/AvatarBorder'
import { IconMenuDeep } from '@tabler/icons-react'
import { motion, AnimatePresence } from 'framer-motion'

const Header = ({handleToggle, toggle}) => {
  const user = useSelector((state) => state.auth.user);
  const options = useSelector((state) => state.options);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState(3);
  const [searchActive, setSearchActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const logoutUser = () => {
    navigate(RouteConstant.ROOT);
    dispatch(setHeaderTextIntitial());
    dispatch(logout());
  }

  const menuItems = [
    // {
    //   label: "My Profile",
    //   icon: <User size="16" className="text-blue-500" />,
    //   color: "primary",
    //   onClick: () => navigate(RouteConstant.PROFILE)
    // },
    // {
    //   label: "Calendar",
    //   icon: <Calendar size="16" className="text-green-500" />,
    //   color: "success",
    //   onClick: () => navigate('/calendar')
    // },
    // {
    //   label: "Help Center",
    //   icon: <HelpCircle size="16" className="text-amber-500" />,
    //   color: "warning",
    //   onClick: () => navigate('/help')
    // },
    {
      label: "Logout",
      icon: <LogOut size="16" className="text-red-500" />,
      color: "danger",
      onClick: logoutUser
    }
  ]

  return (
    <Fragment>
      <div className="w-full h-[70px] flex bg-white border-b border-gray-100 top-0 shadow-sm max-[768px]:bg-white items-center px-6 text-[14px]">
        <div className='flex justify-between w-full items-center'>
          <div className='flex items-center gap-4'>
            <div className='max-[768px]:hidden font-semibold text-[18px] text-gray-800 transition-all duration-300'>
                {options.headerText}
            </div>
            
            <div className={`hidden max-[768px]:flex items-center gap-4`}>
              <div className='flex items-center justify-center h-[70px]'>
                <motion.button 
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleToggle} 
                  className="h-10 w-10 rounded-full hover:bg-gray-100 flex items-center justify-center transition-all duration-200"
                >
                  <IconMenuDeep size={25} className='p-[5px] text-gray-700' />
                </motion.button>
              </div>
              <img src={require('../../assets/images/sire-logo-2.png')} className='w-[140px] h-[24px] max-[500px]:hidden' />
            </div>
          </div>
          
          <div className="flex items-center gap-4">

            <Dropdown placement="bottom-start">
              <DropdownTrigger>
                <div className={`${toggle&&"max-[768px]:block"} flex  items-center gap-4 cursor-pointer`}>
                  <div className='max-[768px]:hidden'>Welcome {user?.username}</div>
                  <div className='relative w-[50px] h-[50px]'>
                    <div className='absolute right-0 top-0'>
                      <AvatarBorder />
                    </div>
                  <img className='w-[50px] h-[50px] rounded-full object-cover p-[4px]' src='https://media.istockphoto.com/id/1389898125/photo/young-woman-chewing-gum-cute-iconic-character-3d-rendering.jpg?s=612x612&w=0&k=20&c=qriGPWcu_8VDPI6VGmyD0oHR80lqMnk8_AtwEPm8Guo=' size='sm' />
                  </div>
                </div>
              </DropdownTrigger>
              <DropdownMenu  aria-label="User Actions" variant="flat">
                <DropdownItem key="profile" className="px-10 py-4 gap-2">
                  <p className="font-bold">Signed in as {user?.username}</p>
                </DropdownItem>
                {menuItems?.map((item, index)=>(
                  <DropdownItem onClick={item.onClick} key={index} startContent={item.icon} color={item.color}>
                    {item.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>

          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Header