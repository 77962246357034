import authService from "../services/authService";
import { useMutation } from "react-query";

const useLoginMutation = (onData) => {
  return useMutation(authService.login, {
    onSuccess: (data) => onData(data),
  });
};

const useUserListMutation = (onData) => {
  return useMutation(authService.userList, {
    onSuccess: (data) => onData(data),
  });
};

const useCreateUserMutation = (onData) => {
  return useMutation(authService.createUser, {
    onSuccess: (data) => onData(data),
  });
};

const useUpdateUserMutation = (onData) => {
  return useMutation(authService.updateUser, {
    onSuccess: (data) => onData(data),
  });
};

const useDemoRegistrationMutation = (onData) => {
  return useMutation(authService.demoRegistration, {
    onSuccess: (data) => onData(data),
  });
};

const authQueries = {
    useLoginMutation,
    useUserListMutation,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDemoRegistrationMutation
};

export default authQueries;
