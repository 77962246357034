import Input from "../components/Input"
import Select from "../components/Select"
import TextArea from "../components/TextArea"

export const QuestionFormInput = [
    {
        name: "question",
        label: "Question",
        type: "text",
        required: true,
        component: TextArea,
        grid: 12
    },
    {
        name: "short_question",
        label: "Short Question",
        type: "text",
        required: true,
        max:255,
        component: Input,
        grid: 6
    },
    {
        name: "roviq",
        label: "ROVIQ Sequence",
        type: "text",
        required: true,
        max:255,
        component: Input,
        grid: 6
    },
    {
        name: "tag",
        label: "Tag",
        type: "select",
        required: true,
        max:255,
        component: Select,
        grid: 6
    },
    {
        name: "publications",
        label: "Publications",
        type: "text",
        required: false,
        component: Input,
        grid: 6
    },
    {
        name: "section",
        label: "Section",
        type: "number",
        required: true,
        max:255,
        component: Input,
        grid: 6
    },
    {
        name: "sub_section",
        label: "Sub Section",
        type: "number",
        required: true,
        max:255,
        component: Input,
        grid: 6
    },
    {
        name: "objectives",
        label: "",
        type: "text",
        component: Input,
    },
    {
        name: "industry_guidance",
        label: "",
        type: "text",
        component: Input,
    },
    {
        name: "inspection_guidance",
        label: "",
        type: "text",
        component: Input,
    },
    {
        name: "suggested_inspector_actions",
        label: "",
        type: "text",
        component: Input,
    },
    {
        name: "expected_evidence",
        label: "",
        type: "text",
        component: Input,
    },
    {
        name: "negative_observation",
        label: "",
        type: "text",
        component: Input,
    },
    {
        name: "process",
        label: "",
        type: "text",
        required: false,
        max:255,
        component: Input,
    },
    {
        name: "human",
        label: "",
        type: "text",
        required: false,
        max:255,
        component: Input,
    },
    {
        name: "hardware",
        label: "",
        type: "text",
        required: false,
        max:255,
        component: Input,
    },
    {
        name: "is_photograph",
        label: "",
        type: "text",
        component: Input,
    },
    {
        name: "checklists",
        required: true,
        array: true,
        label: "",
        type: "text",
        component: Input,
    },
    
]
