import React, { useEffect, useState } from 'react'
import { QuestionFormInput } from '../../../data/QuestionFormInput'
import { useFieldArray, useForm } from 'react-hook-form'
import TextArea from '../../../components/TextArea'
import Input from '../../../components/Input'
import TextEditor from '../../../components/TextEditor'
import RadioGroups from '../../../components/RadioGroup'
import { Box, Check, ChevronLeft, ClipboardList, FileText, Grid, InfoIcon, Layers2, LayoutPanelTop, LayoutTemplate, Library, LibraryBig, ListChecks, Megaphone, Package, PlusIcon, Puzzle, Tag, X } from 'lucide-react'
import questionQueries from '../../../queries/questionQueries'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { RouteConstant } from '../../../routes/RouteConstant'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { generateValidationSchema } from '../../../utils/validationSchema'
import { ValidationErrorMaster } from '../../../components/validationError'
import { setLoader } from '../../../redux/reducers/optionsReducers'
import Loader from '../../../components/Loader'
import Select from '../../../components/Select'
import { IconChevronLeft } from '@tabler/icons-react'
import { motion, AnimatePresence } from 'framer-motion'
import { Checkbox, Select as MultipleSelect, SelectItem } from '@nextui-org/react'

const radioData = [
  {label:"Binary", value: "BINARY"},
  {label:"Graded", value: "GRADED"},
]

const QuestionForm = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const chapter = useSelector((state)=> state.master.chapter)
  const loader = useSelector((state)=> state.options.loader)

  const [data, setData] = useState({})

  const {control, register, handleSubmit, setValue, reset, formState:{errors}, watch} = useForm({
    defaultValues: {checklists: [{checklist: ""}]},
    resolver: yupResolver(generateValidationSchema(QuestionFormInput))
  })

  const qid = useSelector((state) => state.question.qid);

  const viewQuestion = questionQueries.useQuestionViewMutation(
    (res)=>{
        dispatch(setLoader(false))
        reset(res?.data)
        setData(res?.data)
    }
  )

  useEffect(() => {
    if(qid){
      dispatch(setLoader(true))
      viewQuestion.mutateAsync(qid)
    }
  }, []);

  const questionCreate = questionQueries.useQuestionCreateMutation(
    (res)=>{
      toast.success("Successfully created question")
      navigate(RouteConstant.QUESTIONLIST)
    }
  )

  const questionUpdate = questionQueries.useQuestionUpdateMutation(
    (res)=>{
      toast.success("Successfully updated question")
      navigate(RouteConstant.QUESTIONLIST)
    }
  )

  const onSubmit=(data)=>{
    const newValue = {
      ...data,
      chapter_id: chapter?.id
    }
    questionCreate.mutateAsync(newValue)
  }

  const onUpdate=(data)=>{
    questionUpdate.mutateAsync(data)
  }

  const { fields, append, remove } = useFieldArray({
    control,
    name: "checklists",
  });

  const tagOptions = [
    {name:"Core", id: "Core"},
    {name:"Rotational 1", id: "Rotational 1"},
    {name:"Rotational 2", id: "Rotational 2"},
    {name:"Campaign", id: "Campaign"},
    {name:"Conditional", id: "Conditional"},
  ]

  const sectionOption =()=>{
    let temp = []
    for(let i=1; i<=20; i++){
      temp.push({id: i, name: i})
    }
    temp.push({id: 99, name: 99})
    return temp
  }

  const subSectionOption =()=>{
    let temp = []
    for(let i=1; i<=95; i++){
      temp.push({id: i, name: i})
    }
    return temp
  }

  const handleCargo = (name, value) => {
    setData({
      ...data,
      [name]: value ? "YES" : "NO"
    })
    setValue(name, value ? "YES" : "NO")
  }

  if(loader){
    return (
      <Loader />
    )
  }

  return (
    <div>

      <div className='bg-gradient-to-r from-indigo-600 to-purple-400
      shadow-md flex justify-between items-center px-6 py-3 rounded-lg mb-6 max-md:flex-col-reverse max-md:gap-2'>
          <div className='flex gap-4 items-center'>
              <button 
              onClick={() => navigate(-1)} 
              className="p-2 rounded-full hover:bg-gray-200 transition"
              >
                  <IconChevronLeft size="20" className="text-white" />
              </button>
              <div className='font-semibold text-lg text-white'>
                  {chapter?.chapter_no} {chapter?.name}
              </div>
          </div>
      </div>

      <div className='bg-white py-6 px-12 rounded-2xl shadow-2xl'>
        <form onSubmit={handleSubmit(qid?onUpdate:onSubmit)}>
          <div className='grid grid-cols-12 gap-8 text-[14px]'>
              <div className="col-span-12 flex flex-col gap-2">
                <label className='flex items-center space-x-2 text-gray-700 text-[14px]'>
                  <FileText size="18" className='text-primary' />
                  <span>Questions</span>
                </label>
                <TextArea control={control} name="question" items={QuestionFormInput} />
                <ValidationErrorMaster error={errors?.question} />
              </div>
              <div className="col-span-6  flex flex-col gap-2">
                <label className='flex items-center space-x-2 text-gray-700 text-[14px]'>
                  <Layers2 size="18" className='text-primary' />
                  <span>Short Question</span>
                </label>
                <Input control={control} name="short_question" items={QuestionFormInput} />
                <ValidationErrorMaster error={errors?.short_question} />
              </div>
              <div className="col-span-6 flex flex-col gap-2">
                <label className='flex items-center space-x-2 text-gray-700 font-medium'>
                  <Grid size="18" className='text-primary' />
                  <span>ROVIQ Sequence</span>
                </label>
                <Input control={control} name="roviq" items={QuestionFormInput} />
                <ValidationErrorMaster error={errors?.roviq} />
              </div>
              <div className="col-span-6 flex flex-col gap-2">
                <label className='flex items-center space-x-2 text-gray-700 text-[14px]'>
                  <Tag size="18" className='text-primary' />
                  <span>Tag</span>
                </label>
                <Select control={control} name="tag" option={tagOptions} items={QuestionFormInput} />
                <ValidationErrorMaster error={errors?.tag} />
              </div>
              <div className="col-span-3 max-[643px]:col-span-6 flex flex-col gap-2">
                <label className='flex items-center space-x-2 text-gray-700 text-[14px]'>
                  <LibraryBig size="18" className='text-primary' />
                  <span>Section</span>
                </label>
                <Select control={control} name="section" option={sectionOption()} items={QuestionFormInput} />
                <ValidationErrorMaster error={errors?.section} />
              </div>
              <div className="col-span-3 max-[643px]:col-span-6 flex flex-col gap-2">
                <label className='flex items-center space-x-2 text-gray-700 text-[14px]'>
                  <Library size="18" className='text-primary' />
                  <span>Sub Section</span>
                </label>
                <Select control={control} name="sub_section" option={subSectionOption()} items={QuestionFormInput} />
                <ValidationErrorMaster error={errors?.sub_section} />
              </div>
              <div className="col-span-6 flex flex-col gap-2">
                <label className='flex items-center space-x-2 text-gray-700 text-[14px]'>
                  <Megaphone size="18" className='text-primary' />
                  <span>Publications</span>
                </label>
                <TextArea control={control} name="publications" items={QuestionFormInput} />
                <ValidationErrorMaster error={errors?.publications} />
              </div>
              <div className="col-span-12 flex flex-col gap-2  mb-12">
                <label className='flex items-center space-x-2 text-gray-700 text-[14px]'>
                  <span>Objectives</span>
                </label>
                <TextEditor control={control} setValue={setValue} name="objectives" />
              </div>
              <div className="col-span-12 flex flex-col gap-2  mb-12">
                <label className='flex items-center space-x-2 text-gray-700 text-[14px]'>
                  <span>Industry Guidance</span>
                </label>
                <TextEditor control={control} setValue={setValue} name="industry_guidance" />
              </div>
              <div className="col-span-12 flex flex-col gap-2  mb-12">
                <label className='flex items-center space-x-2 text-gray-700 text-[14px]'>
                  <span>Inspection Guidance</span>
                </label>
                <TextEditor control={control} setValue={setValue} name="inspection_guidance" />
              </div>
              <div className="col-span-12 flex flex-col gap-2  mb-12">
                <label className='flex items-center space-x-2 text-gray-700 text-[14px]'>
                  <span>Suggested Inspection Actions</span>
                </label>
                <TextEditor control={control} setValue={setValue} name="suggested_inspector_actions" />
              </div>
              <div className="col-span-12 flex flex-col gap-2  mb-12">
                <label className='flex items-center space-x-2 text-gray-700 text-[14px]'>
                  <span>Expected Evidence</span>
                </label>
                <TextEditor control={control} setValue={setValue} name="expected_evidence" />
              </div>
              <div className="col-span-12 flex flex-col gap-2  mb-12">
                <label className='flex items-center space-x-2 text-gray-700 text-[14px]'>
                  <span>Potential Grounds for a Negative Observation</span>
                </label>
                <TextEditor control={control} setValue={setValue} name="negative_observation" />
              </div>

              <div className="col-span-12 flex gap-16">
                <div className="flex flex-col gap-2">
                  <label className='flex items-center space-x-2 text-gray-700 text-[14px]'>
                    <span>Process</span>
                  </label>
                  <RadioGroups data={radioData} name="process" control={control} />
                </div>
                <div className="flex flex-col gap-2">
                  <label className='flex items-center space-x-2 text-gray-700 text-[14px]'>
                    <span>Human</span>
                  </label>
                  <RadioGroups data={radioData} name="human" control={control} />
                </div>
                <div className="flex flex-col gap-2">
                  <label className='flex items-center space-x-2 text-gray-700 text-[14px]'>
                    <span>Hardware</span>
                  </label>
                  <RadioGroups data={radioData} name="hardware" control={control} />
                </div>
              </div>

              <div className="col-span-12 flex flex-col gap-2">
                  <span className="text-[14px] text-gray-900">Select Cargo Type</span>
                  <div className="col-span-12 flex gap-16">
                      {['Chemical', 'Oil', 'LNG', 'LPG'].map((item, index) => (
                          <div className="flex flex-col gap-2" key={index}>
                              <Checkbox 
                                  isSelected={!!(data?.[item.toLowerCase()] === "YES")} 
                                  onChange={(e) => handleCargo(item.toLowerCase(), e.target?.checked)}
                              >
                                  <span className="text-[14px] text-gray-700">{item}</span>
                              </Checkbox>
                          </div>
                      ))}
                  </div>
              </div>


              <div className="col-span-12 bg-white/80 backdrop-blur-xl shadow-2xl border border-gray-200/50 rounded-3xl p-6 space-y-6">
              
                <div className="flex items-center space-x-4 mb-4">
                  <div className="bg-blue-100 p-3 rounded-2xl">
                    <ClipboardList className="text-blue-600" size={24} />
                  </div>
                  <h2 className="font-semibold text-lg text-gray-900">
                    Checklists
                  </h2>
                </div>

                <AnimatePresence>
                  {fields.map((field, index) => (
                    <motion.div 
                      key={field.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, x: -20 }}
                      transition={{ duration: 0.3 }}
                      className="flex items-center space-x-4 group"
                    >
                      {/* Checklist Input */}
                      <div className="flex-grow relative">
                        <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                          <ListChecks
                            size={20} 
                            className="text-gray-400 group-focus-within:text-blue-500 transition-colors" 
                          />
                        </div>
                        <input
                          key={field.id}
                          placeholder="Enter checklist item..."
                          className="
                            w-full h-14 pl-12 pr-4 
                            bg-gray-100/80 border border-gray-300/50 
                            rounded-2xl 
                            text-gray-900 
                            focus:ring-4 focus:ring-blue-500/20 
                            focus:border-blue-500 
                            transition-all duration-300
                            placeholder-gray-500
                            shadow-sm
                            hover:bg-gray-50
                          "
                          {...register(`checklists.${index}.checklist`)} 
                        />
                        {errors?.checklists?.[index]?.checklist && (
                          <div className="text-red-500 text-sm mt-1 pl-4">
                            {errors.checklists[index].checklist.message}
                          </div>
                        )}
                      </div>

                      {/* Action Buttons */}
                      <div className="flex space-x-3">
                        {/* Add Button */}
                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          type="button"
                          onClick={() => append({ checklist: "" })}
                          className="
                            w-14 h-14 
                            bg-gradient-to-br from-green-400 to-green-600 
                            text-white 
                            rounded-2xl 
                            shadow-lg 
                            hover:shadow-xl 
                            transition-all 
                            flex items-center justify-center
                            group
                          "
                        >
                          <PlusIcon 
                            size={24} 
                            className="group-hover:rotate-180 transition-transform" 
                          />
                        </motion.button>

                        {/* Remove Button */}
                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          type="button"
                          disabled={index === 0}
                          onClick={() => remove(index)}
                          className={`
                            w-14 h-14 
                            rounded-2xl 
                            shadow-lg 
                            hover:shadow-xl 
                            transition-all 
                            flex items-center justify-center
                            group
                            ${index === 0 
                              ? "bg-gray-300 cursor-not-allowed text-gray-500" 
                              : "bg-gradient-to-br from-red-400 to-red-600 text-white"
                            }
                          `}
                        >
                          <X 
                            size={24} 
                            className={`
                              group-hover:rotate-180 
                              transition-transform 
                              ${index === 0 ? "text-gray-500" : "text-white"}
                            `}
                          />
                        </motion.button>
                      </div>
                    </motion.div>
                  ))}
                </AnimatePresence>

                {fields.length === 1 && (
                  <motion.div 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="text-sm text-gray-500 pl-4 mt-2 flex items-center space-x-2"
                  >
                    <InfoIcon size={16} className="text-blue-500" />
                    <span>Add multiple checklist items to capture detailed observations</span>
                  </motion.div>
                )}
              </div>

              <div className="col-span-12 flex gap-2 justify-end mt-2">
                <button className='bg-primary text-white px-8 py-2 rounded-md flex gap-2 items-center' type='reset'>
                  Cancel
                  <X size="20" className='text-red-500' />
                </button>
                <button className='bg-primary text-white px-8 py-2 rounded-md flex gap-2 items-center' type='submit'>
                  Submit
                  <Check size="20" className='text-green-500' />
                </button>
              </div>
          </div>
        </form>
      </div>


    </div>
  )
}

export default QuestionForm