import { Drawer } from '@mui/material'
import { Button, Checkbox, Select, SelectItem } from '@nextui-org/react'
import { IconPencil, IconPlus, IconX } from '@tabler/icons-react';
import React, { Fragment, useEffect, useState } from 'react'
import Input from './Input';
import { useForm } from 'react-hook-form';
import InputMaster from './InputMaster';
import SelectMaster from './SelectMaster';
import authQueries from '../queries/authQueries';
import { useApi } from './ApiProvider';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { generateValidationSchema } from '../utils/validationSchema';

const DrawerComponent = ({data, edit, entityForm, inputFields, btnName, header, vessel_id}) => {

    const [open, setOpen] = useState(false);

    const {control, handleSubmit, reset, formState: {errors}, register, setValue, watch} = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    });

    const openDrawer =()=>{
        document.body.style.overflow = 'hidden';
        document.body.style.height = '100vh';
        if(edit){
            reset(data)
        }
        reset()
        setOpen(true);
    };

    const closeDrawer =()=>{
        document.body.style.overflow = 'auto';
        document.body.style.height = 'auto';
        setOpen(false);
    };

    const onSubmit = async(data)=>{
        if(vessel_id){
            data.vessel_id = vessel_id
        }
        if(edit){
            await entityForm.mutateAsync(data)
            closeDrawer()
        }else{
            await entityForm.mutateAsync(data).then((res)=>{
                if(res?.success){
                    toast.success(res?.message)
                    closeDrawer()
                }
            })
            // reset()
        }
    }

  return (
    <Fragment>
        {
            edit ? 
            <Button isIconOnly onClick={openDrawer} size='sm'>
                <IconPencil size="16" />
            </Button> :
            <Button onClick={openDrawer} startContent={<IconPlus size="16" />} className='max-[526px]:w-full h-[40px] px-6 bg-primary text-white rounded-[7px] font-semibold' size='sm'>
                {btnName}
            </Button>
        }
        <Drawer
        open={open}
        anchor='right'
        onClose={()=>closeDrawer()}
        sx={{
        '& .MuiDrawer-paper': {
        width: '400px',
        maxWidth: '400px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        border: 'none',
        zIndex: 9999
        },
        }}
        >
            <div>
                <div className='flex justify-between font-medium items-center h-[70px] px-6 gap-3 border-b border-[#E6E6E8]'>
                    <h3>{header}</h3>
                    <button onClick={closeDrawer}>
                        <IconX size="20" />
                    </button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='p-6 text-[12] flex flex-col gap-[6px]'>
                        {
                            inputFields?.map((item, index)=> {
                                if(item?.type === 'select'){
                                    return (
                                        <SelectMaster key={index} control={control} item={item} errors={errors} />
                                    )
                                }
                                if(item?.type === 'checkbox'){
                                    const isChecked = watch(item.name) === "YES"
                                    return (
                                        <Checkbox 
                                        defaultSelected={isChecked}
                                        onChange={(e)=> {
                                            setValue(item?.name, e.target.checked ? "YES" : "NO")
                                        }}
                                        key={index}
                                        item={item}
                                        >
                                            <span className='text-[12px] font-[500]'>
                                                {item.label}
                                            </span>
                                        </Checkbox>
                                    )
                                }
                                return (
                                    <InputMaster key={index} register={register} item={item} errors={errors} />
                                )
                            })
                        }
                        <div className='flex justify-end mt-2'>
                            <Button type='submit' className='text-white bg-primary rounded-[7px] font-semibold'>Save</Button>
                        </div>
                    </div>
                </form>
            </div>
        </Drawer>
    </Fragment>
  )
}

export default DrawerComponent