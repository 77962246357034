import React from 'react';
import { Package, Compass, MapPin } from 'lucide-react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="max-w-2xl mx-auto text-center">
        <div className="flex justify-center space-x-4 mb-8">
          <Package className="w-16 h-16 text-blue-500 animate-bounce" />
          <Compass className="w-16 h-16 text-blue-400 animate-pulse" />
          <MapPin className="w-16 h-16 text-blue-600 animate-bounce" />
        </div>
        
        <h1 className="text-6xl font-bold text-gray-900 mb-4">404</h1>
        <h2 className="text-3xl font-semibold text-gray-800 mb-6">Page Not Found</h2>
        
        <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
          <p className="text-gray-600 text-lg mb-4">
            Oops! Looks like you followed a bad link. If you think this is a problem with us, please tell us.
          </p>
          <div className="space-y-2 text-gray-500">
            <p>
                Here are some helpful links instead:
            </p>
          </div>
        </div>
        
        <div className="flex justify-center">
          <Link
          to="/"
          className="bg-blue-500 text-white px-6 py-3 rounded-lg font-medium hover:bg-blue-600 transition-colors"
          >
            Return to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;