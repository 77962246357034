// import React, { Fragment, useState } from 'react'
// import Header from './Header'
// import Sidebar from './Sidebar'
// import { Outlet } from 'react-router-dom'
// import { ChevronLeft, ChevronRight, Menu } from 'lucide-react'
// import { useDispatch, useSelector } from 'react-redux'
// import { toggleBar } from '../../redux/reducers/optionsReducers'
// import Loader from '../../components/Loader'

// const PageLayout = () => {

//   const options = useSelector((state)=> state.options)
//   const dispatch = useDispatch()

//   const [toggle, setToggle] = useState(options.toggleBar)

//   const handleToggle = () => {
//     dispatch(toggleBar())
//     setToggle(!toggle);
//   };

//   return (
//     <Fragment>
//       <Sidebar handleToggle={handleToggle} toggle={toggle} />
//       <div className={`${toggle?"ml-[270px]":"ml-[80px]"} max-[768px]:ml-0`}>
//         <Header handleToggle={handleToggle} toggle={toggle} />
//       </div>
      // <div className={`${toggle?"ml-[270px]":"ml-[80px]"} p-7 max-[410px]:p-0 max-[768px]:ml-0`}>
      //   <div className='w-full max-[410px]:p-3  text-[14px]'>
      //       <Outlet />
      //   </div>
      // </div>
//     </Fragment>
//   )
// }

// export default PageLayout


import React, { Fragment, useState, useEffect } from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import { Outlet, useLocation } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux'
import { toggleBar } from '../../redux/reducers/optionsReducers'
import Loader from '../../components/Loader'

const PageLayout = () => {

  const options = useSelector((state) => state.options)
  const dispatch = useDispatch()
  const [toggle, setToggle] = useState(options.toggleBar)

  const handleToggle = () => {
    dispatch(toggleBar())
    setToggle(!toggle)
  }

  return (
    <Fragment>

      <Sidebar handleToggle={handleToggle} toggle={toggle} />
      
      <div className="flex flex-col min-h-screen">
        <div className={`fixed top-0 right-0 left-0 z-40 ${toggle?"ml-[270px]":"ml-[80px]"} max-[768px]:ml-0 transition-all duration-300`}>
          <Header handleToggle={handleToggle} toggle={toggle} />
        </div>
        <div className={`${toggle?"ml-[270px]":"ml-[80px]"} p-7 max-[410px]:p-0 max-[768px]:ml-0 mt-[70px]`}>
          <div className='w-full max-[410px]:p-3  text-[14px]'>
              <Outlet />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default PageLayout