
import React from "react";
import { Routes, Route, } from "react-router-dom";  
import { RouteConstant } from "./RouteConstant";
import LoginScreen from "../screens/Login/LoginScreen";
import NotFound from "../components/NotFound";
import DemoRegistration from "../screens/Demo/DemoRegistration";

const PublicRoutes = () => {
  return (
    <Routes>
        <Route exact path={RouteConstant.ROOT} element={ <LoginScreen /> } />
        <Route exact path={RouteConstant.DEMO} element={ <DemoRegistration /> } />
        <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PublicRoutes;